/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

/** this file is used by project to add new icons */
import dynamicHydrate from '@bluheadless/ui-logic/src/dynamic-hydration/dynamic-hydrate'
import EmptyIcon from '../icon/empty'

export * from './project-related-icons'
export const Bag2FilledHeaderIcon = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/bag-2-filled-header-icon'),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Bag2FilledHeaderIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/bag-2-filled-header-icon').then((mod) => mod.Bag2FilledHeaderIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const CustomercareIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/customercare-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const CustomercareIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/customercare-icon').then((mod) => mod.CustomercareIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Customize = dynamicHydrate(() => import('@/components/ui/atoms/icons/customize'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const CustomizeSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/customize').then((mod) => mod.CustomizeSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const HeartFilledHeaderIcon = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/heart-filled-header-icon'),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const HeartFilledHeaderIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/heart-filled-header-icon').then((mod) => mod.HeartFilledHeaderIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const WorldIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/world-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const WorldIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/world-icon').then((mod) => mod.WorldIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const World = dynamicHydrate(() => import('@/components/ui/atoms/icons/world'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const WorldSvg = dynamicHydrate(() => import('@/components/ui/atoms/icons/world').then((mod) => mod.WorldSvg), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
